import React from 'react';

let Stats = (props) => {

	return (
		<div>
			
		</div>
	);
}


export default Stats;
