import React from 'react';
import { NavLink } from 'react-router-dom';

import teamsBg from '../img/teams3.jpg';
import ratingsBg from '../img/ratings2.jpg';

let teamsBgStyles = {
	backgroundImage: `url(${teamsBg})`
};

let ratingsBgStyles = {
	backgroundImage: `url(${ratingsBg})`
};

let Home = (props) => {

	function handleClick(value) {
		props.onClick(value);
	}

	return (
		<div className="text-left">
			<h5 className="font-weight-bold uppercase mb-1">Next Match</h5>
			<p>Team 1 v Team 2</p>

			<div className="bg-image my-4" style={teamsBgStyles}>
				<NavLink onClick={() => handleClick("teams")} to="/teams">
					<button className="btn btn-tomato">View Teams</button>
				</NavLink>
			</div>

			<div className="bg-image" style={ratingsBgStyles}>
				<NavLink onClick={() => handleClick("stats")} to="/teams">
					<button className="btn btn-tomato right">Player Stats</button>
				</NavLink>
			</div>

		</div>
	);
}

export default Home;
